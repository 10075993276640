import React,{useState,useRef,useCallback,useEffect,} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import '../../../css/exploitation.css'


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Exp_prx_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [client,setClient]=useState([]);
    const [produit,setProduit]=useState([]);
    const [selectClient,setSelectClient]=useState('');
    const [selectProduit,setSelectProduit]=useState('');
    const [prix,setPrix]=useState('');
    const [actPrix, setActPrix] =useState([]);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const reg=/\(|\)/g
    const service="_prx.php"


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
        setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      setSelectClient('');setSelectProduit('');setActPrix([]);setPrix('')
    };

    const handleClose = () => { setOpen(false); };
    
    //fetch client
    const fetchCli= useCallback(async(serv="_cli.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
                setClient(response.data.jData);    
            })
        } 
      },[Gfunc])

    //fetch client handler
    const handleOpenCli= useCallback(async () => {
      if(client.length===0) fetchCli()
      },[client,fetchCli])

     //fetch client
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
                setProduit(response.data.jData);    
            })
        } 
      },[Gfunc])

    //fetch client handler
    const handleOpenProd= useCallback(async () => {
      if(produit.length===0) fetchProd()
      },[produit,fetchProd])

     //fetch client
    const fetchPrix= useCallback(async(val,val1) => {
        const response = await Gfunc.axiosPostAction(service,'getprice',{clientId:val,produitId:val1});
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
                setActPrix(response.data.jData);  
            })
        } 
    },[Gfunc])

    useEffect(() => {
        if(selectClient!==''&&selectProduit!==''&&open) {fetchPrix(selectClient,selectProduit);}
    }, [selectClient,selectProduit]);

    //submit
    const handleSubmit= async(e) =>{
        const response=await axiosPostAction(service,"add",{date:Gfunc.formatDate(new Date()),clientId:selectClient,produitId:selectProduit,
                                                            price:parseFloat(prix)})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
          if(response.data.jData){
            setOpen(false);
            props.callback();
          }
        })
      }
    }


  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU PRIX</Button>
      <Dialog
         open={open}
         id='frns'
         onClose={handleClose}
         PaperComponent={PaperComponent}
         aria-labelledby="draggable-dialog-title"
         PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau prix</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                  <div id='row-prx'>
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectClient(client[Gfunc.getIndex(client,newValue,"name")].id)}} 
                                id='iCli' 
                                onFocus={handleOpenCli}
                               // value={selectClient}
                                options={client.map((option) => option.name)}
                                renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    label='Client' size="small" margin='dense' variant="outlined" required />)} 
                    />
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectProduit(produit[Gfunc.getIndex(produit,newValue,"name")].id)}} 
                                id='iPrd' 
                                onFocus={handleOpenProd}
                               // value={selectProduit}
                                options={produit.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Produit' size="small" margin='dense' variant="outlined" required />)} 
                   />
                  </div>
                  <div id='mtn-prx'> 
                    <TextField margin="dense"  label='Prix' size="small" type='number' value={prix} variant="outlined" className='smallText' 
                InputProps={{ inputProps: { min: 0, step: "0.001" } }} onChange={e => setPrix(e.target.value)} required
                             helperText={
                                 (actPrix.length!==0)?
                                 (actPrix.price!==''&&actPrix.price!==null)
                                ?'Le prix actuel: '+new Intl.NumberFormat().format(actPrix.price)+' ('+actPrix.indic.replace(reg,'--')+'). '
                                :(actPrix.price===null)?"Aucun prix définit.":''
                                :''}
                   />
                  </div>
                  
              </ValidatorForm>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
