import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField,FormControlLabel,Checkbox,Backdrop,CircularProgress} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Print} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogPrint from './dig_part_imprimer_el'


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_prt_imprimer(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [disabledClt, setDisabledClt] =useState(false);
    const [disabledCom, setDisabledCom] =useState(false);
    const [action, setAction] =useState(false);
    const [checked, setChecked] =useState(true);
    const [messageA, setMessageA] =useState('');
    const [clt, setClt] =useState([]);
    const [prd, setPrd] =useState([]);
    const [com, setCom] =useState([]);
    const [data, setData] =useState([]);
    const [dataa, setDataa] =useState([]);
    const [selectClt, setSelectClt] =useState('IRH Print');
    const [selectPrd, setSelectPrd] =useState('');
    const [selectCom, setSelectCom] =useState('');
    const [qte, setQte] =useState('');
  const [quota, setQuota] = useState('');
    const [selectClient, setSelectClient] =useState('');
    const service="_prt.php"
    const classes=useStyles();


    //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
 const handleClickOpen = () => { 
      setOpen(true); setQte('');setSelectClt('IRH Print');setSelectPrd('');setSelectClient('');setSelectCom('');setDisabledClt(false);
      setDisabledCom(false);setChecked(true)
      setData([]);
      if(clt.length===0){handleOpenClt()}
    };

 const handleClose = () => { setOpen(false); setData([])};
 const handleCloseP = () => { setOpenP(false);setData([]) };
    
  //fetch client 
  const fetchQuota = useCallback(async (id) => {
    const det = []
    const response = await Gfunc.axiosPostAction("_prt.php", "getq", { prodDetId: id })
    if (response.data.err) {
      // props.handleAlert(true, 'error', response.data.err)
      setQuota("");
    } else
      Gfunc.axiosResponse(response, () => {
        setQuota(response.data.jData);
      })
  }, [Gfunc, props]);

 //fetch client 
 const fetchCli= useCallback(async(id) => {
  const det=[]
    const response = await Gfunc.axiosPostAction("_cli.php","gets",{})
    if(response.data.err){
      setOpen(false)
     props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setClt(response.data.jData);   
    })
  },[Gfunc,props]);

//handle the call for fetch client
const handleOpenClt=(event)=>{
    if(clt.length===0)
    fetchCli();
}
 //fetch products 
 const fetchPrd= useCallback(async(id) => {
    const det=[]
    const response = await Gfunc.axiosGetAction("_prd.php","getstockqte")
    if(response.data.err){
      setOpen(false)
     props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        //setPrdT(lodashClonedeep(response.data.jData))
        response.data.jData.map((el)=>{
           det.push({prodId:el.prodId,nom:el.nom+' [Stock:'+el.stock+']',achat:el.achat})
        })
        setPrd(det);   
    })
  },[Gfunc,props]);

//handle the call for fetch products
const handleOpenPrd=(event)=>{
    if(prd.length===0)
    fetchPrd();
}
 //fetch commercial 
 const fetchCom= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_usr.php","getcom")
    if(response.data.err){
      setOpen(false)
      props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setCom(response.data.jData);   
    })
  },[Gfunc,props]);

//handle the call for fetch commercial
const handleOpenCom=(event)=>{
    if(com.length===0)
    fetchCom();
}

const handleChange=(e)=>{
    setChecked(!checked) 
  
  }

const handleSubmit= useCallback(async(e) =>{
  const cid = Gfunc.getIndex(clt, selectClt, "name")
  if (cid >= 0) {
    setAction(true)
    const dat=[];var owner=''
    const p = prd[Gfunc.getIndex(prd, selectPrd.nom.toString(), "nom")].prodId;
    const client=clt[Gfunc.getIndex(clt,selectClt,"name")].id
    if(selectCom!==''||selectClient!==''){
      owner=(selectCom!=='')?com[Gfunc.getIndex(com,selectCom,"name")].id.toString():(selectClient!=='')?clt[Gfunc.getIndex(clt,selectClient,"name")].id:""
    }
    const response=await axiosPostAction(service,"print",{clientId:client,prodDetId:p,qte:parseInt(qte),logo:checked,owner:owner})
    setAction(false)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    axiosResponse(response,()=>{
        if(response.data.jData){
           dat.push(response.data.jData.date)
           dat.push(response.data.jData.hour)
           setData(response.data.jData.tikets)
           setDataa(dat)
           setOpenP(true)
        }
    })
  }
  } else handleAlert(true, "error", "Le client selectionne n'est pas disponibleLe client sélectionné n'est pas disponible")
},[clt,selectClt,selectPrd,selectClient,selectCom,qte])

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Print/>}>imprimer</Button>
      <Dialog
            open={open}
        id='gprx'
        className='printTick'
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
        // style={{ overflow: "hidden !important" }}
      >
        <Backdrop className={classes.backdropDiag} open={action} >
           <CircularProgress color="inherit" />
         </Backdrop>  
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Imprimer un ticket</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent style={{ overflow: "hidden !important" }}>
                <ValidatorForm ref={useRef(null)} id="Form" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            {/* <div id='print-prod'> */}
            <div id='print_row_1'>
                        <Autocomplete
                                disableClearable
                                className='smallAuto'
                onChange={(e, newValue) => { setSelectPrd(newValue); fetchQuota(newValue.prodId) }} 
                                onFocus={handleOpenPrd}
                value={selectPrd}
                options={prd}
                getOptionLabel={(option) => option.nom?.toString()}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Produit" size="small"  margin='dense' variant='outlined' required autoFocus
                                     />
                                )} />

              <TextField type='number' className='smallText' variant="outlined" size="small" label="Quota" id="iQtdis" name="iQt"
                value={quota} disabled />                          
                    </div>
                    <div id='print_row_1'>
                       <Autocomplete
                                disableClearable
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectClt(newValue);}} 
                                //onFocus={handleOpenClt}
                                value={selectClt}
                                options={clt.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Client" size="small"  margin='dense' variant='outlined'required
                                     />
                                )} />
                      <TextField type='number' className='smallText' variant="outlined" size="small" label="Qte." id="iQ" name="iQ" 
                            value={qte} onChange={e =>setQte(e.target.value)}  required />
                       
                    </div>
                   
                        
                   <fieldset className="fieldsetTopPrint" id="re">
                   <legend>Informations d'impression</legend>
                    <div id='print_roww_1'>
                    <Autocomplete
                                disableClearable
                                disabled={disabledCom}
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectCom(newValue);setDisabledClt(true)}} 
                                onFocus={handleOpenCom}
                                value={(selectCom!=='')?(selectCom):null}
                                options={com.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Commercial" size="small"  margin='dense' variant='outlined' 
                                     />
                                )} />
                    <Autocomplete
                                disableClearable
                                disabled={disabledClt}
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectClient(newValue);setDisabledCom(true)}} 
                                onFocus={handleOpenClt}
                                value={(selectClient!=='')?(selectClient):null}
                                options={clt.map((option) =>option.name)}
                                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Client" size="small"  margin='dense' variant='outlined' 
                                                                        //autoFocus
                                     />
                                )} />
                    </div>
                    <FormControlLabel
                            control={<Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked"color="primary" /> }
                            label="Logo"
                        />
                    </fieldset>
              </ValidatorForm>
              <div>
                {
                  (data.length!==0)?
                     <DialogPrint open={openP} handleCloseP={handleCloseP} handleClose={handleClose} data={data} dataa={dataa}
                  logo={checked} callback={() => props.callback()} param={param} setDatadet={props.setDatadet} produit={selectPrd.nom.toString()}
                      owner={(selectCom!=='')?com[Gfunc.getIndex(com,selectCom,"name")].id.toString():(selectClient!=='')?clt[Gfunc.getIndex(clt,selectClient,"name")].id:""}/>
                  :<></>
                }
              </div>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="Form" color="primary" startIcon={<Print />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
